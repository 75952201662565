export const langOptions = [
  { value: 'arabic', label: 'Arabic', ocrCode: undefined,
    toEnglish: {  
      expansionPercent: 30, 
      advancedBooking: 190, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 190, 
    }
  },
  { value: 'chinese', label: 'Chinese', ocrCode: undefined, 
    toEnglish: {  
      expansionPercent: 0, 
      advancedBooking: 190, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 190, 
    }
  },
  { value: 'czech', label: 'Czech', ocrCode: 'cs-CZ', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 165, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 165, 
    }
  },
  { value: 'danish', label: 'Danish', ocrCode: 'da-DK', 
    toEnglish: {  
      expansionPercent: 25, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 170, 
    }
  },
  { value: 'dutch', label: 'Dutch', ocrCode: 'nl-NL', 
    toEnglish: {  
      expansionPercent: 25, 
      advancedBooking: 155, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 155, 
    }
  },
  { value: 'english', label: 'English', ocrCode: 'en-US' },
  { value: 'estonian', label: 'Estonian', ocrCode: 'et-EE', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 180, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 180, 
    }
  },
  { value: 'farsi', label: 'Farsi/Persian', ocrCode: undefined,
    toEnglish: {  
      expansionPercent: 30, 
      advancedBooking: 190, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 190, 
    }
  },
  { value: 'finnish', label: 'Finnish', ocrCode: 'fi-FI', 
    toEnglish: {  
      expansionPercent: 30, 
      advancedBooking: 180, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 180, 
    }
  },
  { value: 'french', label: 'French', ocrCode: 'fr-FR', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 140, 
    },
    fromEnglish: {
      expansionPercent: 20, 
      advancedBooking: 140, 
    }
  },
  { value: 'german', label: 'German', ocrCode: 'de-DE', 
    toEnglish: {  
      expansionPercent: 25, 
      advancedBooking: 145, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 155, 
    }
  },
  { value: 'greek', label: 'Greek', ocrCode: 'el-GR', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 170, 
    }
  },
  { value: 'hebrew', label: 'Hebrew', ocrCode: undefined, 
    toEnglish: {  
      expansionPercent: 30, 
      advancedBooking: 180, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 180, 
    }
  },
  { value: 'hungarian', label: 'Hungarian', ocrCode: 'hu-HU', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 170, 
    }
  },
  { value: 'italian', label: 'Italian', ocrCode: 'it-IT', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 140, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 140, 
    }
  },
  { value: 'japanese', label: 'Japanese', ocrCode: undefined,
    toEnglish: {  
      expansionPercent: 0, 
      advancedBooking: 190, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 190, 
    }
  },
  { value: 'korean', label: 'Korean', ocrCode: undefined,
    toEnglish: {  
      expansionPercent: 0, 
      advancedBooking: 190, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 190, 
    }
  },
  { value: 'latvian', label: 'Latvian', ocrCode: 'lv-LV', 
    toEnglish: {  
      expansionPercent: 10, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 170,
    }
  },
  { value: 'norwegian', label: 'Norwegian', ocrCode: 'nb-NO', 
    toEnglish: {  
      expansionPercent: 25, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 170, 
    }
  },
  { value: 'polish', label: 'Polish', ocrCode: 'pl-PL', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 140, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 140, 
    }
  },
  { value: 'portuguese', label: 'Portuguese', ocrCode: 'pt-PT', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 140, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 140, 
    }
  },
  { value: 'punjabi', label: 'Punjabi', ocrCode: undefined, 
    toEnglish: {  
      expansionPercent: 0, 
      advancedBooking: 160, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 160, 
    }
  },
  { value: 'romanian', label: 'Romanian', ocrCode: 'ro-RO', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 165, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 165, 
    }
  },
  { value: 'russian', label: 'Russian', ocrCode: 'ru-RU', 
    toEnglish: {  
      expansionPercent: 30, 
      advancedBooking: 160, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 160, 
    }
  },
  { value: 'spanish', label: 'Spanish', ocrCode: 'eu-ES', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 140, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 140, 
    }
  },
  { value: 'swedish', label: 'Swedish', ocrCode: 'sv-SE', 
    toEnglish: {  
      expansionPercent: 25, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 170, 
    }
  },
  { value: 'turkish', label: 'Turkish', ocrCode: 'tr-TR', 
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 175, 
    },
    fromEnglish: {
      expansionPercent: 15, 
      advancedBooking: 170, 
    }
  },
  { value: 'ukranian', label: 'Ukrainian', ocrCode: 'uk-UA', 
    toEnglish: {  
      expansionPercent: 30, 
      advancedBooking: 175, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 165, 
    }
  },
  { value: 'urdu', label: 'Urdu', ocrCode: undefined, 
    toEnglish: {  
      expansionPercent: 0, 
      advancedBooking: 170, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 170, 
    }
  },
  { value: 'welsh', label: 'Welsh', ocrCode: undefined,
    toEnglish: {  
      expansionPercent: 15, 
      advancedBooking: 145, 
    },
    fromEnglish: {
      expansionPercent: 0, 
      advancedBooking: 145, 
    }
  },
];