import React from 'react';
import { useState } from 'react';
import { Box, Button, TextField, Stack, Autocomplete } from '@mui/material';
import { countryCodes } from './countryCodes.js';

const UserDetails = ({ nextStep, prevStep, handleChange, values }) => {
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function isValidNumber(phone) {
    return /^[\d -]+$/.test(phone);
  }

  const Continue = e => {

    // Error handling
    if (values.firstName === '') {
      setFirstNameError(true);
    }
    if (values.lastName === '') {
      setLastNameError(true);
    }
    if (values.email === '') {
      setEmailError('Please enter your email');
    } else if (!isValidEmail(values.email)) {
      setEmailError('Please enter a valid email');
    }
    if (values.phoneNumber === '') {
      setPhoneNumberError('Please enter your phone number');
    } else if (!isValidNumber(values.phoneNumber)) {
      setPhoneNumberError('Please enter a valid phone number');
    }
    if (values.firstName === '' || values.lastName === '' || values.email === '' || values.phoneNumber === '' || !isValidEmail(values.email) || !isValidNumber(values.phoneNumber)) {
      return;
    }

    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
    <Box width='inherit' height='inherit' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
      <Box sx={{ marginTop: '1rem', padding: '0px 2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack width='100%' sx={{ alignItems: 'center' }}>
          <TextField 
            className="textField"
            error={firstNameError}
            helperText={firstNameError ? 'Please enter your first name' : '' }
            label="First Name" 
            variant="outlined" 
            sx={{ marginTop: "1rem" }} 
            onChange={(e) => {
              setFirstNameError(false);
              handleChange('firstName')(e);
            }}
            defaultValue={values.firstName} 
          />
          <TextField 
            className="textField"
            error={lastNameError}
            helperText={lastNameError ? 'Please enter your last name' : '' }
            label="Last Name" 
            variant="outlined" 
            sx={{ marginTop: "1rem" }} 
            onChange={(e) => {
              setLastNameError(false);
              handleChange('lastName')(e);
            }}
            defaultValue={values.lastName} 
          />
          <TextField 
            className="textField"
            error={emailError}
            helperText={emailError}
            label="Email" 
            variant="outlined" 
            sx={{ marginTop: "1rem" }} 
            onChange={(e) => {
              setEmailError(false);
              handleChange('email')(e);
            }}
            defaultValue={values.email} 
          />
          <Box className="textField" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Autocomplete
              disablePortal
              value={values.countryCode}
              onChange={(event, selectedOption) => {
                handleChange('countryCode')(selectedOption);
              }}
              disableClearable
              options={countryCodes}
              sx={{ width: '20%', marginTop: '1rem' }}
              popupIcon={""}
              componentsProps={{
                paper: {
                  sx: {
                    width: '20rem'
                  }
                }
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.label} {option.countryName}
                </Box>
              )}
              renderInput={(params) => 
                <TextField
                  {...params} 
                  label="Prefix" 
                />
              }
            />
            <TextField 
              error={phoneNumberError}
              helperText={phoneNumberError}
              label="Phone Number" 
              variant="outlined" 
              sx={{ marginTop: "1rem", width: '75%' }} 
              onChange={(e) => {
                setPhoneNumberError(false);
                handleChange('phoneNumber')(e);
              }}
              defaultValue={values.phoneNumber} 
            />
          </Box>
        </Stack>
      </Box>
      <Box sx={{ marginBottom: '4rem', display: 'flex', justifyContent: 'space-between', margin: '3rem' }} >
        <Button 
          onClick={Previous}
          variant="outlined"
          size="small"
          color="error"
          sx={{ marginRight: '1rem'}}
        >
          Go Back
        </Button>
        <Button 
          onClick={Continue}
          variant="outlined"
          size="large"
          sx={{ marginLeft: '1rem'}}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default UserDetails