import React from 'react';
import { useState } from 'react';
import { Button, Box, Typography, FormGroup, FormControlLabel, Checkbox, FormHelperText, FormControl } from '@mui/material';

const Start = ({ nextStep }) => {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToContact, setAgreeToContact] = useState(false);
  const [agreeToTermsError, setAgreeToTermsError] = useState(false);
  const [agreeToContactError, setAgreeToContactError] = useState(false);

  const Continue = e => {
    if (!agreeToTerms) {
      setAgreeToTermsError(true);
    }
    if (!agreeToContact) {
      setAgreeToContactError(true);
    }
    if (!agreeToTerms || !agreeToContact) {
      return;
    }
    e.preventDefault();
    nextStep();
  }


  return (
    <Box width='inherit' height='inherit' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
      <Box sx={{ marginTop: '2rem', padding: '0px 2rem' }}>
        <Typography variant="h6" sx={{ paddingBottom: '1rem' , textAlign: 'center' }}>
          Get a quote for your translation in 5 minutes
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '4rem', textAlign: 'center' }} >
        <FormControl
          error={agreeToTermsError || agreeToContactError}
        >
          <FormGroup sx={{ marginBottom: '2rem'}}>
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={agreeToTerms}
                  onChange={e => {setAgreeToTerms(e.target.checked); setAgreeToTermsError(false)}}
                  name="agreeToTerms"
                />
              } 
              label={
                <span class="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-ahj2mt-MuiTypography-root">
                  I have read and agree to Aplomb's <a rel="noreferrer" target="_blank" href="https://aplombtranslations.com/terms-conditions/">T&Cs</a> and <a rel="noreferrer" target="_blank" href="https://aplombtranslations.com/privacy-policy/"> Privacy Policy</a>
                </span>
              }
              labelPlacement="start"
              sx={{ marginLeft: '1rem', marginRight: '1rem', display: 'inline-block', textAlign: 'center' }}
            />
            {agreeToTermsError ? <FormHelperText>Please agree to T&Cs</FormHelperText> : ''}
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={agreeToContact}
                  onChange={e => {setAgreeToContact(e.target.checked); setAgreeToContactError(false)}}
                  name="agreeToContact"
                />
              } 
              label="I agree for Aplomb to contact me regarding this quote"
              labelPlacement="start"
              sx={{ marginLeft: '1rem', marginRight: '1rem', display: 'inline-block', textAlign: 'center' }}
            />
            {agreeToContactError ? <FormHelperText>Please agree to contact preferences</FormHelperText> : ''}
          </FormGroup>
        </FormControl>
        <Button 
          onClick={Continue}
          variant="outlined"
          sx={{ width: '14rem', height: '5rem', fontSize: '1.4rem' }}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  )
}

export default Start