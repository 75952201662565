import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Stack, Typography, CircularProgress } from '@mui/material';

const Quote = ({ prevStep, values, handleChange }) => {

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const Previous = async e => {
    e.preventDefault();
    await handleChange('quote')(0);
    prevStep();
  }

  return (
    <Box width='inherit' height='inherit' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
      <Box sx={{ marginTop: '1rem', padding: '0px 2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {showSpinner ? <CircularProgress /> : (
          <Stack>
            {values.quote === 0 ? (
              <Stack>
                <Typography sx={{ textAlign:"center" }} variant="h4" gutterBottom>
                  Quote Request Submitted
                </Typography>
                <Typography sx={{ textAlign:"center" }} variant="h5" gutterBottom>
                  Your quote requires specialist attention, we will send you the details shortly.
                </Typography>
                <Typography sx={{ textAlign:"center" }} variant="h6" gutterBottom>
                  Thank you for your patience.
                </Typography>
              </Stack>
            ) : (
              <Stack>
                <Typography sx={{ textAlign:"center" }} variant="h1">
                  £ {values.quote}
                </Typography>
                <Typography sx={{ textAlign:"center" }} variant="h6" marginBottom={"5rem"}>
                  plus VAT
                </Typography>
                <Typography sx={{ textAlign:"center" }} variant="h5" gutterBottom>
                  Thank you for using our quoting service!
                </Typography>
                <Typography sx={{ textAlign:"center" }} variant="h6" gutterBottom>
                  One of our advisors will be in touch shortly to discuss your quote.
                </Typography>
                <Typography sx={{ textAlign:"center" }} variant="h6" gutterBottom>
                  Or if urgent please call us on <a href="tel:+44 020 7831 9444" >+44 020 7831 9444</a>
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
      </Box>
      <Box sx={{ marginBottom: '4rem', display: 'flex', justifyContent: 'space-between', margin: '3rem' }} >
        <Button 
          onClick={Previous}
          sx={{ width: '20%' }}
          variant="outlined"
          color="error"
          disabled={showSpinner}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  )
}

export default Quote