export const countryCodes = [
    {
      label: "+7 840",
      countryName: "Abkhazia"
    },
    {
      label: "+93",
      countryName: "Afghanistan"
    },
    {
      label: "+355",
      countryName: "Albania"
    },
    {
      label: "+213",
      countryName: "Algeria"
    },
    {
      label: "+1 684",
      countryName: "American Samoa"
    },
    {
      label: "+376",
      countryName: "Andorra"
    },
    {
      label: "+244",
      countryName: "Angola"
    },
    {
      label: "+1 264",
      countryName: "Anguilla"
    },
    {
      label: "+1 268",
      countryName: "Antigua and Barbuda"
    },
    {
      label: "+54",
      countryName: "Argentina"
    },
    {
      label: "+374",
      countryName: "Armenia"
    },
    {
      label: "+297",
      countryName: "Aruba"
    },
    {
      label: "+247",
      countryName: "Ascension"
    },
    {
      label: "+61",
      countryName: "Australia"
    },
    {
      label: "+672",
      countryName: "Australian External Territories"
    },
    {
      label: "+43",
      countryName: "Austria"
    },
    {
      label: "+994",
      countryName: "Azerbaijan"
    },
    {
      label: "+1 242",
      countryName: "Bahamas"
    },
    {
      label: "+973",
      countryName: "Bahrain"
    },
    {
      label: "+880",
      countryName: "Bangladesh"
    },
    {
      label: "+1 246",
      countryName: "Barbados"
    },
    {
      label: "+1 268",
      countryName: "Barbuda"
    },
    {
      label: "+375",
      countryName: "Belarus"
    },
    {
      label: "+32",
      countryName: "Belgium"
    },
    {
      label: "+501",
      countryName: "Belize"
    },
    {
      label: "+229",
      countryName: "Benin"
    },
    {
      label: "+1 441",
      countryName: "Bermuda"
    },
    {
      label: "+975",
      countryName: "Bhutan"
    },
    {
      label: "+591",
      countryName: "Bolivia"
    },
    {
      label: "+387",
      countryName: "Bosnia and Herzegovina"
    },
    {
      label: "+267",
      countryName: "Botswana"
    },
    {
      label: "+55",
      countryName: "Brazil"
    },
    {
      label: "+246",
      countryName: "British Indian Ocean Territory"
    },
    {
      label: "+1 284",
      countryName: "British Virgin Islands"
    },
    {
      label: "+673",
      countryName: "Brunei"
    },
    {
      label: "+359",
      countryName: "Bulgaria"
    },
    {
      label: "+226",
      countryName: "Burkina Faso"
    },
    {
      label: "+257",
      countryName: "Burundi"
    },
    {
      label: "+855",
      countryName: "Cambodia"
    },
    {
      label: "+237",
      countryName: "Cameroon"
    },
    {
      label: "+1",
      countryName: "Canada"
    },
    {
      label: "+238",
      countryName: "Cape Verde"
    },
    {
      label: "+ 345",
      countryName: "Cayman Islands"
    },
    {
      label: "+236",
      countryName: "Central African Republic"
    },
    {
      label: "+235",
      countryName: "Chad"
    },
    {
      label: "+56",
      countryName: "Chile"
    },
    {
      label: "+86",
      countryName: "China"
    },
    {
      label: "+61",
      countryName: "Christmas Island"
    },
    {
      label: "+61",
      countryName: "Cocos-Keeling Islands"
    },
    {
      label: "+57",
      countryName: "Colombia"
    },
    {
      label: "+269",
      countryName: "Comoros"
    },
    {
      label: "+242",
      countryName: "Congo"
    },
    {
      label: "+243",
      countryName: "Congo, Dem. Rep. of (Zaire)"
    },
    {
      label: "+682",
      countryName: "Cook Islands"
    },
    {
      label: "+506",
      countryName: "Costa Rica"
    },
    {
      label: "+385",
      countryName: "Croatia"
    },
    {
      label: "+53",
      countryName: "Cuba"
    },
    {
      label: "+599",
      countryName: "Curacao"
    },
    {
      label: "+537",
      countryName: "Cyprus"
    },
    {
      label: "+420",
      countryName: "Czech Republic"
    },
    {
      label: "+45",
      countryName: "Denmark"
    },
    {
      label: "+246",
      countryName: "Diego Garcia"
    },
    {
      label: "+253",
      countryName: "Djibouti"
    },
    {
      label: "+1 767",
      countryName: "Dominica"
    },
    {
      label: "+1 809",
      countryName: "Dominican Republic"
    },
    {
      label: "+670",
      countryName: "East Timor"
    },
    {
      label: "+56",
      countryName: "Easter Island"
    },
    {
      label: "+593",
      countryName: "Ecuador"
    },
    {
      label: "+20",
      countryName: "Egypt"
    },
    {
      label: "+503",
      countryName: "El Salvador"
    },
    {
      label: "+240",
      countryName: "Equatorial Guinea"
    },
    {
      label: "+291",
      countryName: "Eritrea"
    },
    {
      label: "+372",
      countryName: "Estonia"
    },
    {
      label: "+251",
      countryName: "Ethiopia"
    },
    {
      label: "+500",
      countryName: "Falkland Islands"
    },
    {
      label: "+298",
      countryName: "Faroe Islands"
    },
    {
      label: "+679",
      countryName: "Fiji"
    },
    {
      label: "+358",
      countryName: "Finland"
    },
    {
      label: "+33",
      countryName: "France"
    },
    {
      label: "+596",
      countryName: "French Antilles"
    },
    {
      label: "+594",
      countryName: "French Guiana"
    },
    {
      label: "+689",
      countryName: "French Polynesia"
    },
    {
      label: "+241",
      countryName: "Gabon"
    },
    {
      label: "+220",
      countryName: "Gambia"
    },
    {
      label: "+995",
      countryName: "Georgia"
    },
    {
      label: "+49",
      countryName: "Germany"
    },
    {
      label: "+233",
      countryName: "Ghana"
    },
    {
      label: "+350",
      countryName: "Gibraltar"
    },
    {
      label: "+30",
      countryName: "Greece"
    },
    {
      label: "+299",
      countryName: "Greenland"
    },
    {
      label: "+1 473",
      countryName: "Grenada"
    },
    {
      label: "+590",
      countryName: "Guadeloupe"
    },
    {
      label: "+1 671",
      countryName: "Guam"
    },
    {
      label: "+502",
      countryName: "Guatemala"
    },
    {
      label: "+224",
      countryName: "Guinea"
    },
    {
      label: "+245",
      countryName: "Guinea-Bissau"
    },
    {
      label: "+595",
      countryName: "Guyana"
    },
    {
      label: "+509",
      countryName: "Haiti"
    },
    {
      label: "+504",
      countryName: "Honduras"
    },
    {
      label: "+852",
      countryName: "Hong Kong SAR China"
    },
    {
      label: "+36",
      countryName: "Hungary"
    },
    {
      label: "+354",
      countryName: "Iceland"
    },
    {
      label: "+91",
      countryName: "India"
    },
    {
      label: "+62",
      countryName: "Indonesia"
    },
    {
      label: "+98",
      countryName: "Iran"
    },
    {
      label: "+964",
      countryName: "Iraq"
    },
    {
      label: "+353",
      countryName: "Ireland"
    },
    {
      label: "+972",
      countryName: "Israel"
    },
    {
      label: "+39",
      countryName: "Italy"
    },
    {
      label: "+225",
      countryName: "Ivory Coast"
    },
    {
      label: "+1 876",
      countryName: "Jamaica"
    },
    {
      label: "+81",
      countryName: "Japan"
    },
    {
      label: "+962",
      countryName: "Jordan"
    },
    {
      label: "+7 7",
      countryName: "Kazakhstan"
    },
    {
      label: "+254",
      countryName: "Kenya"
    },
    {
      label: "+686",
      countryName: "Kiribati"
    },
    {
      label: "+965",
      countryName: "Kuwait"
    },
    {
      label: "+996",
      countryName: "Kyrgyzstan"
    },
    {
      label: "+856",
      countryName: "Laos"
    },
    {
      label: "+371",
      countryName: "Latvia"
    },
    {
      label: "+961",
      countryName: "Lebanon"
    },
    {
      label: "+266",
      countryName: "Lesotho"
    },
    {
      label: "+231",
      countryName: "Liberia"
    },
    {
      label: "+218",
      countryName: "Libya"
    },
    {
      label: "+423",
      countryName: "Liechtenstein"
    },
    {
      label: "+370",
      countryName: "Lithuania"
    },
    {
      label: "+352",
      countryName: "Luxembourg"
    },
    {
      label: "+853",
      countryName: "Macau SAR China"
    },
    {
      label: "+389",
      countryName: "Macedonia"
    },
    {
      label: "+261",
      countryName: "Madagascar"
    },
    {
      label: "+265",
      countryName: "Malawi"
    },
    {
      label: "+60",
      countryName: "Malaysia"
    },
    {
      label: "+960",
      countryName: "Maldives"
    },
    {
      label: "+223",
      countryName: "Mali"
    },
    {
      label: "+356",
      countryName: "Malta"
    },
    {
      label: "+692",
      countryName: "Marshall Islands"
    },
    {
      label: "+596",
      countryName: "Martinique"
    },
    {
      label: "+222",
      countryName: "Mauritania"
    },
    {
      label: "+230",
      countryName: "Mauritius"
    },
    {
      label: "+262",
      countryName: "Mayotte"
    },
    {
      label: "+52",
      countryName: "Mexico"
    },
    {
      label: "+691",
      countryName: "Micronesia"
    },
    {
      label: "+1 808",
      countryName: "Midway Island"
    },
    {
      label: "+373",
      countryName: "Moldova"
    },
    {
      label: "+377",
      countryName: "Monaco"
    },
    {
      label: "+976",
      countryName: "Mongolia"
    },
    {
      label: "+382",
      countryName: "Montenegro"
    },
    {
      label: "+1664",
      countryName: "Montserrat"
    },
    {
      label: "+212",
      countryName: "Morocco"
    },
    {
      label: "+95",
      countryName: "Myanmar"
    },
    {
      label: "+264",
      countryName: "Namibia"
    },
    {
      label: "+674",
      countryName: "Nauru"
    },
    {
      label: "+977",
      countryName: "Nepal"
    },
    {
      label: "+31",
      countryName: "Netherlands"
    },
    {
      label: "+599",
      countryName: "Netherlands Antilles"
    },
    {
      label: "+1 869",
      countryName: "Nevis"
    },
    {
      label: "+687",
      countryName: "New Caledonia"
    },
    {
      label: "+64",
      countryName: "New Zealand"
    },
    {
      label: "+505",
      countryName: "Nicaragua"
    },
    {
      label: "+227",
      countryName: "Niger"
    },
    {
      label: "+234",
      countryName: "Nigeria"
    },
    {
      label: "+683",
      countryName: "Niue"
    },
    {
      label: "+672",
      countryName: "Norfolk Island"
    },
    {
      label: "+850",
      countryName: "North Korea"
    },
    {
      label: "+1 670",
      countryName: "Northern Mariana Islands"
    },
    {
      label: "+47",
      countryName: "Norway"
    },
    {
      label: "+968",
      countryName: "Oman"
    },
    {
      label: "+92",
      countryName: "Pakistan"
    },
    {
      label: "+680",
      countryName: "Palau"
    },
    {
      label: "+970",
      countryName: "Palestinian Territory"
    },
    {
      label: "+507",
      countryName: "Panama"
    },
    {
      label: "+675",
      countryName: "Papua New Guinea"
    },
    {
      label: "+595",
      countryName: "Paraguay"
    },
    {
      label: "+51",
      countryName: "Peru"
    },
    {
      label: "+63",
      countryName: "Philippines"
    },
    {
      label: "+48",
      countryName: "Poland"
    },
    {
      label: "+351",
      countryName: "Portugal"
    },
    {
      label: "+1 787",
      countryName: "Puerto Rico"
    },
    {
      label: "+974",
      countryName: "Qatar"
    },
    {
      label: "+262",
      countryName: "Reunion"
    },
    {
      label: "+40",
      countryName: "Romania"
    },
    {
      label: "+7",
      countryName: "Russia"
    },
    {
      label: "+250",
      countryName: "Rwanda"
    },
    {
      label: "+685",
      countryName: "Samoa"
    },
    {
      label: "+378",
      countryName: "San Marino"
    },
    {
      label: "+966",
      countryName: "Saudi Arabia"
    },
    {
      label: "+221",
      countryName: "Senegal"
    },
    {
      label: "+381",
      countryName: "Serbia"
    },
    {
      label: "+248",
      countryName: "Seychelles"
    },
    {
      label: "+232",
      countryName: "Sierra Leone"
    },
    {
      label: "+65",
      countryName: "Singapore"
    },
    {
      label: "+421",
      countryName: "Slovakia"
    },
    {
      label: "+386",
      countryName: "Slovenia"
    },
    {
      label: "+677",
      countryName: "Solomon Islands"
    },
    {
      label: "+27",
      countryName: "South Africa"
    },
    {
      label: "+500",
      countryName: "South Georgia and the South Sandwich Islands"
    },
    {
      label: "+82",
      countryName: "South Korea"
    },
    {
      label: "+34",
      countryName: "Spain"
    },
    {
      label: "+94",
      countryName: "Sri Lanka"
    },
    {
      label: "+249",
      countryName: "Sudan"
    },
    {
      label: "+597",
      countryName: "SuricountryName"
    },
    {
      label: "+268",
      countryName: "Swaziland"
    },
    {
      label: "+46",
      countryName: "Sweden"
    },
    {
      label: "+41",
      countryName: "Switzerland"
    },
    {
      label: "+963",
      countryName: "Syria"
    },
    {
      label: "+886",
      countryName: "Taiwan"
    },
    {
      label: "+992",
      countryName: "Tajikistan"
    },
    {
      label: "+255",
      countryName: "Tanzania"
    },
    {
      label: "+66",
      countryName: "Thailand"
    },
    {
      label: "+670",
      countryName: "Timor Leste"
    },
    {
      label: "+228",
      countryName: "Togo"
    },
    {
      label: "+690",
      countryName: "Tokelau"
    },
    {
      label: "+676",
      countryName: "Tonga"
    },
    {
      label: "+1 868",
      countryName: "Trinidad and Tobago"
    },
    {
      label: "+216",
      countryName: "Tunisia"
    },
    {
      label: "+90",
      countryName: "Turkey"
    },
    {
      label: "+993",
      countryName: "Turkmenistan"
    },
    {
      label: "+1 649",
      countryName: "Turks and Caicos Islands"
    },
    {
      label: "+688",
      countryName: "Tuvalu"
    },
    {
      label: "+1 340",
      countryName: "U.S. Virgin Islands"
    },
    {
      label: "+256",
      countryName: "Uganda"
    },
    {
      label: "+380",
      countryName: "Ukraine"
    },
    {
      label: "+971",
      countryName: "United Arab Emirates"
    },
    {
      label: "+44",
      countryName: "United Kingdom"
    },
    {
      label: "+1",
      countryName: "United States"
    },
    {
      label: "+598",
      countryName: "Uruguay"
    },
    {
      label: "+998",
      countryName: "Uzbekistan"
    },
    {
      label: "+678",
      countryName: "Vanuatu"
    },
    {
      label: "+58",
      countryName: "Venezuela"
    },
    {
      label: "+84",
      countryName: "Vietnam"
    },
    {
      label: "+1 808",
      countryName: "Wake Island"
    },
    {
      label: "+681",
      countryName: "Wallis and Futuna"
    },
    {
      label: "+967",
      countryName: "Yemen"
    },
    {
      label: "+260",
      countryName: "Zambia"
    },
    {
      label: "+255",
      countryName: "Zanzibar"
    },
    {
      label: "+263",
      countryName: "Zimbabwe"
    }
  ];