import React from 'react';
import { useState } from 'react';
import { Box, Button, TextField, Grid, Autocomplete, Checkbox, FormGroup, FormControlLabel, Typography, CircularProgress } from '@mui/material';
import { langOptions } from '../../langOptions.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const UserDetails = ({ nextStep, prevStep, handleChange, values }) => {
  const [languageFromError, setLanguageFromError] = useState(false);
  const [languageToError, setLanguageToError] = useState(false);
  const [wordCountError, setWordCountError] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const Continue = async (e, quote) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("file", values.selectedFiles);
  
    for (const [key, value] of Object.entries(values)) {
      if (key === "selectedFiles") {
        continue;
      } else if (key === "languageFrom" || key === "languageTo" || key === "countryCode") {
        formData.append(key, value.label);
      } else if (key === "quote") {
        if (!quote) {
          formData.append(key, "Quote requires specialist attention");
        } else if (quote < 80) {
          formData.append(key, 80);
        } else {
          formData.append(key, Math.round(quote));
        }
      } else {
        formData.append(key, value);
      }
    }
  
    try {
      const response = await fetch(
        process.env.REACT_APP_ENV === "development"
          ? "http://localhost:3001/email"
          : window.location.origin + ":443/email",
        {
          method: "POST",
          body: formData,
        }
      );
  
      const data = await response.json();
      console.log(data);
  
      setIsLoading(false);
      nextStep();
    } catch (error) {
      console.error("Error:", error);
      setNetworkError(true);
      setIsLoading(false);
    }
  };

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  const getQuote = async e => {
    setIsLoading(true);

    // Error handling
    if (!values.languageFrom) {
      await setLanguageFromError(true);
    }
    if (!values.languageTo) {
      await setLanguageToError(true);
    }
    if (values.wordCount <= 0) {
      await setWordCountError(true);
    }
    if (!values.languageFrom || !values.languageTo || values.wordCount <= 0) {
      return;
    }

    let dayDifference = values.dateRequired.diff(dayjs(), 'day');
    let speed = '';
    let quote = 0;

    if (values.languageFrom.value === values.languageTo.value) {
      setLanguageToError(true);
    }

    // Calculate speed needed
    if (dayDifference < 2) {
      speed = 'urgent';
    } else if (dayDifference < 7) {
      speed = 'faster';
    } else {
      speed = 'standard';
    }

    if (values.languageFrom.value === 'english') {
      // From english
      quote = ((values.wordCount * (1 + (values.languageTo.fromEnglish.expansionPercent / 100))) / 1000) 
        * ((speed === 'urgent') ? (values.languageTo.fromEnglish.advancedBooking * 1.3)
        : (speed === 'faster') ? (values.languageTo.fromEnglish.advancedBooking * 1.15)
        : (speed === 'standard') && values.languageTo.fromEnglish.advancedBooking);
    } else if (values.languageTo.value === 'english') {
      // To english
      quote = ((values.wordCount * (1 + (values.languageFrom.toEnglish.expansionPercent / 100))) / 1000) 
        * ((speed === 'urgent') ? (values.languageFrom.toEnglish.advancedBooking * 1.3)
        : (speed === 'faster') ? (values.languageFrom.toEnglish.advancedBooking * 1.15)
        : (speed === 'standard') && values.languageFrom.toEnglish.advancedBooking);
    }
    if (quote < 80) {
      quote = 80;
    }
    await handleChange('quote')(Math.round(quote));
    Continue(e, quote);
  }

  return (
    <Box width='inherit' height='inherit' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
      <Box sx={{ marginTop: '1rem', padding: '0px 2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {isLoading ? <CircularProgress /> : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              value={values.languageFrom}
              onChange={(event, selectedOption) => {
                setLanguageFromError(false);
                handleChange('languageFrom')(selectedOption);
              }}
              options={langOptions}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              sx={{ width: '100%' }}
              popupIcon={""}
              renderInput={(params) => 
                <TextField 
                  error={languageFromError} 
                  helperText={languageFromError ? "Please enter a language to translate from" : ""} 
                  {...params} 
                  label="From Language" 
                />
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Autocomplete
              disablePortal
              value={values.languageTo}
              onChange={(event, selectedOption) => {
                setLanguageToError(false);
                handleChange('languageTo')(selectedOption);
              }}
              options={langOptions}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              sx={{ width: '100%' }}
              popupIcon={""}
              renderInput={(params) => 
                <TextField 
                  error={languageToError} 
                  helperText={languageToError ? "Please enter a language to translate to" : ""} 
                  {...params} 
                  label="Into Language" 
                />
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ marginTop: '1rem'}}>
            <TextField
              error={wordCountError}
              helperText={wordCountError ? "Please estimate the word count" : ""}  
              label="Word count"
              type="number"
              value={values.wordCount}
              onChange={(event) => {
                setWordCountError(false);
                handleChange('wordCount')(event);
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '1rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date required"
                value={values.dateRequired}
                onChange={handleChange("dateRequired")}
                sx={{ width: '100%' }}
                disablePast
              />
            </LocalizationProvider>
          </Grid>
            <Grid item xs={6} sx={{ marginTop: '1rem'}}>
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Checkbox
                      checked={values.notarisationRequired}
                      onChange={handleChange('notarisationRequired')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } 
                  label="Notarisation required"
                  labelPlacement="start"
                  sx={{ justifyContent: 'space-between', marginLeft: '0px', marginRight: '0.5rem' }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '1rem'}}>
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Checkbox
                      checked={values.legalisationRequired}
                      onChange={handleChange('legalisationRequired')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } 
                  label="Legalisation required"
                  labelPlacement="start"
                  sx={{ justifyContent: 'space-between', marginLeft: '0.5rem' }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '1rem'}}>
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Checkbox
                      checked={values.wetSignatureRequired}
                      onChange={handleChange('wetSignatureRequired')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } 
                  label="Wet signature required"
                  labelPlacement="start"
                  sx={{ justifyContent: 'space-between', marginLeft: '0px', marginRight: '0.5rem' }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: '1rem'}}>
              <FormGroup>
                <FormControlLabel 
                  control={
                    <Checkbox
                      checked={values.certifiedTranslationRequired}
                      onChange={handleChange('certifiedTranslationRequired')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  } 
                  label="Certified translation required"
                  labelPlacement="start"
                  sx={{ justifyContent: 'space-between', marginLeft: '0.5rem' }}
                />
              </FormGroup>
            </Grid>
        </Grid>
        )}
      </Box>
      {networkError && <Typography variant="p" sx={{ marginTop: '1rem', padding: '0px 2rem', textAlign: 'center', color: '#d32f2f' }}>A network error has occurred, please refresh and try again</Typography>}
      <Box sx={{ marginBottom: '4rem', display: 'flex', justifyContent: 'space-between', margin: '3rem' }} >
        <Button 
          onClick={Previous}
          variant="outlined"
          size="small"
          color="error"
        >
          Go Back
        </Button>
        <Button 
          onClick={getQuote}
          variant="outlined"
          size="large"
          disabled={networkError || isLoading}
        >
          GET QUOTE
        </Button>
      </Box>
    </Box>
  )
}

export default UserDetails