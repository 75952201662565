import React from 'react';
import { useState } from 'react';
import { Button, Box, Typography, Stack, CircularProgress, Autocomplete, TextField } from '@mui/material';
import { langOptions } from '../../langOptions.js';

const Start = ({ nextStep, prevStep, handleUpload, handleChange, values }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canEstimate, setCanEstimate] = useState(true);
  const [error, setError] = useState(null);
  const [languageFromError, setLanguageFromError] = useState("");

  const handleFileUpload = (e) => {
    if (file && file !== e.target.files[0]) {
      setCanEstimate(true);
    }
    handleUpload(e.target.files[0]);
    setFile(e.target.files[0]);
    setError(undefined);
  };

  const Continue = async e => {
    if (!values.selectedFiles) {
      setError('Please upload a file');
      return;
    }
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  const EstimateWordCount = async e => {
    setCanEstimate(false);
    setIsLoading(true);
    if (!values.languageFrom) {
      setLanguageFromError('Please select a language');
      setIsLoading(false);
      setCanEstimate(true);
      return;
    }
    if (!values.languageFrom.ocrCode) {
      setLanguageFromError('This language is not supported for estimation');
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append('file', values.selectedFiles);
    formData.append('language', values.languageFrom.ocrCode);
    const response = await fetch(process.env.REACT_APP_ENV === "development" ? "http://localhost:3001/ocr" : window.location.origin + ":443/ocr", {
      method: 'POST',
      body: formData,
    });
    await response.json()
      .then((data) => {
        if (data.success) {
          handleChange('wordCount')(data.data);
        } else if (data.error) {
          setError(data.error);
        }
      })
  
    setIsLoading(false);
  }

  return (
    <Box width='inherit' height='inherit' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
      <Box sx={{ marginTop: '1rem', padding: '0px 2rem' }}>
        <Stack sx={{ display:'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Typography variant="h6" sx={{ paddingBottom: '4rem' , textAlign: 'center' }}>
          Attach the document you would like translating
        </Typography>
        <Button variant="contained" component="label" disabled={isLoading}>
          Upload
          <input hidden type="file" onChange={handleFileUpload} />
        </Button>
        <Typography variant="p" sx={{ paddingTop: '1rem' , textAlign: 'center' }}>
          {file ? file.name : values.selectedFiles ? values.selectedFiles.name : 'No file selected'}
        </Typography>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Autocomplete
            disablePortal
            value={values.languageFrom}
            onChange={(event, selectedOption) => {
              setCanEstimate(true);
              setLanguageFromError(false);
              handleChange('languageFrom')(selectedOption);
            }}
            options={langOptions}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            sx={{ width: '70%', marginTop: '3rem' }}
            popupIcon={""}
            renderInput={(params) => 
              <TextField 
                error={languageFromError} 
                helperText={languageFromError} 
                {...params} 
                label="From Language" 
              />
            }
          />
        </Box>
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '3rem' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button
              onClick={EstimateWordCount}
              variant="outlined"
              size="small"
              sx={{ marginTop: '1rem' }}
              disabled={!canEstimate || !values.selectedFiles || !values.languageFrom}
            >
              ESTIMATE WORD COUNT
            </Button>
            {values.selectedFiles && values.wordCount > 0 && (
              <Typography variant="p" sx={{ paddingTop: '0.5rem' , textAlign: 'center' }}>
                Estimated word count: {isLoading ? 'Loading...' : values.wordCount}
              </Typography>
            )}
            {error && (
              <Typography variant="p" sx={{ paddingTop: '0.5rem' , textAlign: 'center', color: '#d32f2f' }}>
                {error}
              </Typography>
            )}
          </>
        )}
        </Stack>
      </Box>
      <Box sx={{ marginBottom: '4rem', display: 'flex', justifyContent: 'space-between', margin: '3rem' }} >
        <Button 
          onClick={Previous}
          variant="outlined"
          size="small"
          color="error"
          disabled={isLoading}
        >
          Go Back
        </Button>
        <Button 
          onClick={Continue}
          variant="outlined"
          size="large"
          disabled={isLoading || !values.selectedFiles || !values.languageFrom}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}

export default Start