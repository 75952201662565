import React, { Component } from 'react'
import Start from '../Start/Start'
import UserDetails from '../UserDetails/UserDetails'
import UploadDocument from '../UploadDocument/UploadDocument'
import JobForm from '../JobForm/JobForm'
import Quote from '../Quote/Quote'
import MultiStepProgressBar from '../MultiStepProgressBar/MultiStepProgressBar'
import { Box, Paper, Typography } from '@mui/material';
import logo from '../../logo.png';
import dayjs from "dayjs";

export default class Controller extends Component {

  state = {
    step: 1,
    email: '',
    phoneNumber: '',
    countryCode: {
      label: "+44",
      countryName: "United Kingdom"
    },
    firstName: '',
    lastName: '',
    selectedFiles: undefined,
    languageFrom: undefined,
    languageTo: undefined,
    wordCount: 0,
    dateRequired: new dayjs(new Date()),
    notarisationRequired: false,
    legalisationRequired: false,
    wetSignatureRequired: false,
    certifiedTranslationRequired: false,
    quote: 0
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  }

  handleChange = input => async e => {
    return new Promise(resolve => {
      if (input === 'languageFrom' || input === 'languageTo' || input === 'quote') {
        this.setState({ [input]: e }, () => {
          resolve();
        });
      } else if (input === 'dateRequired') {
        this.setState({ [input]: dayjs(e) }, () => {
          resolve();
        });
      } else if (input === 'notarisationRequired' || input === 'legalisationRequired' || input === 'wetSignatureRequired') {
        this.setState({ [input]: e.target.checked }, () => {
          resolve();
        });
      } else {
        this.setState({ [input]: e.target ? e.target.value : e }, () => {
          resolve();
        });
      }
    });
  }

  handleUpload = (files) => {
    this.setState({ selectedFiles: files });
  }

  nextStepNumber = (stepNumber) => {
    switch (stepNumber) {
      case "1":
        this.setState({
          step: 1
        });
        break;
      case "2":
        this.setState({
          step: 2
        });
        break;
      case "3":
        this.setState({
          step: 3
        });
        break;
      case "4":
        this.setState({
          step: 4
        });
        break;
      case "5":
        this.setState({
          step: 5
        });
        break;
      default:
        this.setState({
          step: 1
        });
    }
  };

  render() {
    const { step } = this.state;
    const { email, firstName, lastName, phoneNumber, selectedFiles, languageFrom, languageTo, wordCount, dateRequired, notarisationRequired, legalisationRequired, wetSignatureRequired, quote, countryCode, certifiedTranslationRequired } = this.state;
    const values = { email, firstName, lastName, phoneNumber, selectedFiles, languageFrom, languageTo, wordCount, dateRequired, notarisationRequired, legalisationRequired, wetSignatureRequired, quote, countryCode, certifiedTranslationRequired  };

    switch (step) {
      case 1: 
        return (
          <Box sx={{ backgroundColor: '#005BAA', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column',  }}>
            <Paper className='containerBox' elevation={24} sx={{ paddingTop: '2rem', marginTop:"10rem", bgcolor: '#ffffff', borderRadius: '0.7rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="h3" sx={{ paddingBottom: '1rem' , textAlign: 'center', color: '-webkit-linear-gradient(#00E1FD, #FC007A)' }}>Need a quote?</Typography>
              <MultiStepProgressBar page={step}/>
              <Box sx={{ width: '100%', height: '100%', paddingTop: '1rem' }} >
                <Start
                  nextStep={this.nextStep}
                />
              </Box>
            </Paper>
          </Box>
        )
      case 2: 
        return (
          <Box sx={{ backgroundColor: '#005BAA', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column',  }}>
            <Paper className='containerBox' elevation={24} sx={{ paddingTop: '2rem', marginTop:"10rem", bgcolor: '#ffffff', borderRadius: '0.7rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="h3" sx={{ paddingBottom: '1rem' , textAlign: 'center', color: '-webkit-linear-gradient(#00E1FD, #FC007A)' }}>Some details...</Typography>
              <MultiStepProgressBar page={step}/>
              <Box sx={{ width: '100%', height: '100%', paddingTop: '1rem' }} >
                <UserDetails
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChange}
                  values={values}
                />
              </Box>
            </Paper>
          </Box>
        )
      case 3: 
        return (
          <Box sx={{ backgroundColor: '#005BAA', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column',  }}>
            <Paper className='containerBox' elevation={24} sx={{ paddingTop: '2rem', marginTop:"10rem", bgcolor: '#ffffff', borderRadius: '0.7rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="h3" sx={{ paddingBottom: '1rem' , textAlign: 'center', color: '-webkit-linear-gradient(#00E1FD, #FC007A)' }}>Upload document</Typography>
              <MultiStepProgressBar page={step}/>
              <Box sx={{ width: '100%', height: '100%', paddingTop: '1rem' }} >
                <UploadDocument
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChange}
                  handleUpload={this.handleUpload}
                  values={values}
                />
              </Box>
            </Paper>
          </Box>
        )
      case 4:
        return (
          <Box sx={{ backgroundColor: '#005BAA', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column',  }}>
            <Paper className='containerBox' elevation={24} sx={{ paddingTop: '2rem', marginTop:"10rem", bgcolor: '#ffffff', borderRadius: '0.7rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="h3" sx={{ paddingBottom: '1rem' , textAlign: 'center', color: '-webkit-linear-gradient(#00E1FD, #FC007A)' }}>How can we help?</Typography>
              <MultiStepProgressBar page={step}/>
              <Box sx={{ width: '100%', height: '100%', paddingTop: '1rem' }} >
                <JobForm
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChange}
                  values={values}
                />
              </Box>
            </Paper>
          </Box>
        )
      case 5:
        return (
          <Box sx={{ backgroundColor: '#005BAA', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column',  }}>
            <Paper className='containerBox' elevation={24} sx={{ paddingTop: '2rem', marginTop:"10rem", bgcolor: '#ffffff', borderRadius: '0.7rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="h3" sx={{ paddingBottom: '1rem' , textAlign: 'center', color: '-webkit-linear-gradient(#00E1FD, #FC007A)' }}>Your quote</Typography>
              <Box sx={{ width: '100%', height: '100%', paddingTop: '1rem' }} >
                <Quote 
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChange}
                  values={values}
                />
              </Box>
            </Paper>
          </Box>
        )
      default: 
         // do nothing
    }

    return (
      <div>Controller</div>
    )
  }
}
